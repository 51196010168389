import React from "react";
import { Group, LoadingOverlay, Table, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import Section from "../Section";
import {
	getParkingInformation,
	ParkingSpacesInformation,
} from "../../../utilities/api/jelbi-dashboard-api";
import { getStationParkingQueryKey } from "../../../utilities/client/query-keys";
import { ReactComponent as ElectricalIcon } from "../../../assets/icons/bolt-line.svg";
import { ReactComponent as NonElectricalIcon } from "../../../assets/icons/bolt-forbidden-line.svg";
import { ReactComponent as NotOccupiedIcon } from "../../../assets/icons/parking-line.svg";
import { ReactComponent as OccupiedIcon } from "../../../assets/icons/not-parking-line.svg";
import { ReactComponent as DefectIcon } from "../../../assets/icons/wrench-line.svg";
import { ReactComponent as SumIcon } from "../../../assets/icons/sum.svg";

type ParkingStats = {
	total: number;
	occupied: number;
	free: number;
	defect: number;
};

type ParkingLotsProps = {
	stationId: string;
};

const HEADER_HEIGHT = "42px";
const DETAILS_ROW_HEIGHT = "32px";
const ICON_SIZE = "26px";
const FONT_SIZE = "md";
const SECTION_TITLE = "Stellplätze";

function ParkingLots({ stationId }: ParkingLotsProps) {
	const { isPending, data: response } = useQuery({
		queryKey: getStationParkingQueryKey(stationId),
		queryFn: () => getParkingInformation(stationId),
		// The query will not execute until routeMatch.params.stationId
		enabled: !!stationId,
	});

	const iconStyle = {
		height: ICON_SIZE,
		width: ICON_SIZE,
	};

	if (isPending) {
		return (
			<Section title={SECTION_TITLE}>
				<LoadingOverlay visible />
			</Section>
		);
	}

	const getErrorMessage = (status?: number) => {
		switch (status) {
			case 404:
				return "Für diese Station stehen keine Informationen zu Stellplätzen zur Verfügung.";
			case 500:
				return "Daten konnten nicht von multiGuide abgerufen werden.";
			default:
				return "Daten konnten nicht von multiGuide abgerufen werden.";
		}
	};

	if (!response?.data || response.status !== 200) {
		return (
			<Section title={SECTION_TITLE}>
				<Text size="sm">{getErrorMessage(response?.status)}</Text>
			</Section>
		);
	}

	const { total, free, occupied, defect, parkingSpaces } =
		response.data as ParkingSpacesInformation;
	const { electrical, nonElectrical } = parkingSpaces.reduce(
		(
			acc,
			{
				type,
				total: parkingSpaceTotal,
				free: parkingSpaceFree,
				occupied: parkingSpaceOccupied,
				defect: parkingSpaceDefect,
			}
		) => {
			if (type === "electric") {
				acc.electrical = {
					total: parkingSpaceTotal,
					free: parkingSpaceFree,
					occupied: parkingSpaceOccupied,
					defect: parkingSpaceDefect,
				};
			} else {
				acc.nonElectrical.total += parkingSpaceTotal;
				acc.nonElectrical.free += parkingSpaceFree;
				acc.nonElectrical.occupied += parkingSpaceOccupied;
				acc.nonElectrical.defect += parkingSpaceDefect;
			}
			return acc;
		},
		{
			electrical: { total: 0, free: 0, occupied: 0, defect: 0 },
			nonElectrical: { total: 0, free: 0, occupied: 0, defect: 0 },
		}
	);

	const tableRow = (
		icon: React.ReactNode,
		{
			total: rowTotal,
			occupied: rowOccupied,
			free: rowFree,
			defect: rowDefect,
		}: ParkingStats,
		isBold = false,
		isWithMarginBottom = false
	) => {
		const fontWeight = isBold ? "bold" : "normal";
		const extraMargin = isWithMarginBottom ? 24 : 0;

		return (
			<Table.Tr h={DETAILS_ROW_HEIGHT}>
				<Table.Th>
					<Group justify="center" mb={extraMargin}>
						{icon}
					</Group>
				</Table.Th>
				<Table.Td>
					<Text
						fw={fontWeight}
						fz={FONT_SIZE}
						lh={DETAILS_ROW_HEIGHT}
						ta="center"
						h={DETAILS_ROW_HEIGHT}
						mb={extraMargin}
					>
						{rowTotal}
					</Text>
				</Table.Td>
				<Table.Td>
					<Text
						fw={fontWeight}
						fz={FONT_SIZE}
						lh={DETAILS_ROW_HEIGHT}
						ta="center"
						h={DETAILS_ROW_HEIGHT}
						mb={extraMargin}
					>
						{rowFree}
					</Text>
				</Table.Td>
				<Table.Td>
					<Text
						fw={fontWeight}
						fz={FONT_SIZE}
						lh={DETAILS_ROW_HEIGHT}
						ta="center"
						h={DETAILS_ROW_HEIGHT}
						mb={extraMargin}
					>
						{rowOccupied}
					</Text>
				</Table.Td>
				<Table.Td>
					<Text
						fw={fontWeight}
						fz={FONT_SIZE}
						lh={DETAILS_ROW_HEIGHT}
						ta="center"
						h={DETAILS_ROW_HEIGHT}
						mb={extraMargin}
					>
						{rowDefect}
					</Text>
				</Table.Td>
			</Table.Tr>
		);
	};

	return (
		<Section title={SECTION_TITLE}>
			<Table withRowBorders={false} verticalSpacing={0}>
				<Table.Thead bg="none">
					<Table.Tr h={42}>
						<Table.Th />
						<Table.Th>
							<Group justify="center" h={HEADER_HEIGHT}>
								<SumIcon style={iconStyle} title="Anzahl der Stellplätze" />
							</Group>
						</Table.Th>
						<Table.Th>
							<Group justify="center" h={HEADER_HEIGHT}>
								<NotOccupiedIcon style={iconStyle} title="Freie Stellplätze" />
							</Group>
						</Table.Th>
						<Table.Th>
							<Group justify="center" h={HEADER_HEIGHT}>
								<OccupiedIcon style={iconStyle} title="Belegte Stellplätze" />
							</Group>
						</Table.Th>
						<Table.Th>
							<Group justify="center" h={HEADER_HEIGHT}>
								<DefectIcon
									style={iconStyle}
									title="Stellplätze mit defekten Sensoren"
								/>
							</Group>
						</Table.Th>
					</Table.Tr>
				</Table.Thead>
				<Table.Tbody>
					{tableRow(null, { total, occupied, free, defect }, true, true)}
					{tableRow(
						<ElectricalIcon
							title="Elektrifizierte Stellplätze"
							style={iconStyle}
						/>,
						electrical
					)}
					{tableRow(
						<NonElectricalIcon
							title="Nicht elektrifizierte Stellplätze"
							style={iconStyle}
						/>,
						nonElectrical
					)}
				</Table.Tbody>
			</Table>
		</Section>
	);
}
export default ParkingLots;
