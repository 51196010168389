import React, { ReactElement } from "react";
import { Avatar } from "@mantine/core";
import { VehicleType } from "../../utilities/api/jelbi-dashboard-api";
import {
	getVehicleTypeImage,
	getVehicleTypeName,
} from "../../utilities/client/vehicles";

type VehicleImageProps = {
	vehicleType: VehicleType;
	size?: number;
};

function VehicleImage({
	vehicleType: type,
	size = 64,
}: VehicleImageProps): ReactElement {
	const { PUBLIC_URL } = process.env;

	return (
		<Avatar
			size={size}
			alt={getVehicleTypeName(type)}
			src={PUBLIC_URL + getVehicleTypeImage(type)}
		/>
	);
}

export default VehicleImage;
