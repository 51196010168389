import React from "react";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconExclamationCircleFilled } from "@tabler/icons-react";

export const showSubmitErrorNotification = () => {
	notifications.show({
		id: "failChangeIssueStatus",
		position: "top-center",
		message: "Änderungen an Problemmeldung konnten nicht übermittelt werden!",
		color: "red",
		icon: <IconExclamationCircleFilled />,
	});
};

export const showSubmitSuccessfulNotification = () => {
	notifications.show({
		id: "successChangeIssueStatus",
		position: "top-center",
		message: "Änderungen an Problemmeldung übermittelt!",
		color: "green",
		icon: <IconCheck />,
	});
};
