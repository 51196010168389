import React, { ReactElement, useEffect, useState } from "react";
import { ComboboxData, LoadingOverlay } from "@mantine/core";
import { subYears } from "date-fns";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { DateValue } from "@mantine/dates";
import { Issue } from "../../../utilities/api/jelbi-dashboard-api";
import {
	QUERY_KEY_ALL_ISSUES,
	QUERY_KEY_ALL_TRACKED_ISSUES,
} from "../../../utilities/client/query-keys";
import Error from "../../Error/Error";
import Table from "../../Table/Table";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import issuesService from "../../../services/issuesService";
import useGetUserRoles from "../../../utilities/client/hooks/useGetUserRoles";
import {
	filterRecords,
	getColumConfig,
	stationName,
	updateCachedIssues,
} from "./utils/helper";

type IssuesTableProps = {
	showTrackedIssuesOnly?: boolean;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	showIssueModal: () => void;
};

function IssuesTable({
	showTrackedIssuesOnly = false,
	page,
	setPage,
	showIssueModal,
}: IssuesTableProps): ReactElement {
	const navigate = useNavigate();
	const isMobile = useIsMobileView();
	const userRoles = useGetUserRoles();
	const queryClient = useQueryClient();

	const [records, setRecords] = useState<Issue[]>([]);
	const [stationForSelectData, setStationForSelectData] =
		useState<ComboboxData>([]);
	const [stationFilterValue, setStationFilterValue] = useState<string[]>([]);
	const [statusFilterValue, setStatusFilterValue] = useState<string[]>([]);
	const [priorityFilterValue, setPriorityFilterValue] = useState<string[]>([]);
	const [problemFilterValue, setProblemFilterValue] = useState<string[]>([]);
	const [dateFilterValue, setDateFilterValue] = useState<DateValue>();
	const queryEndDate = new Date().toISOString();
	const queryStartDate = subYears(queryEndDate, 1).toISOString();

	const {
		data: issues,
		isPending,
		isError,
	} = useQuery({
		queryKey: [
			showTrackedIssuesOnly
				? QUERY_KEY_ALL_TRACKED_ISSUES
				: QUERY_KEY_ALL_ISSUES,
		],
		queryFn: () => {
			if (showTrackedIssuesOnly) {
				return issuesService.fetchIssues({
					start: queryStartDate,
					end: queryEndDate,
					isTracked: true,
				});
			}
			return issuesService.fetchIssues({
				start: queryStartDate,
				end: queryEndDate,
			});
		},
	});

	const trackingMutation = useMutation({
		mutationFn: (issue: Issue) =>
			issuesService.editIssue(issue.id, !issue.isTracked),
		onSuccess: () => updateCachedIssues(queryClient),
	});
	const trackIssue = (issue: Issue) => trackingMutation.mutate(issue);

	const columns = getColumConfig(
		isMobile,
		stationForSelectData,
		statusFilterValue,
		setStatusFilterValue,
		priorityFilterValue,
		setPriorityFilterValue,
		problemFilterValue,
		setProblemFilterValue,
		stationFilterValue,
		setStationFilterValue,
		dateFilterValue,
		setDateFilterValue,
		queryClient,
		userRoles,
		issues?.results || [],
		trackIssue
	);

	useEffect(() => {
		if (issues) {
			const filteredRecords = filterRecords(
				issues.results,
				stationFilterValue,
				statusFilterValue,
				priorityFilterValue,
				problemFilterValue,
				dateFilterValue
			);
			setRecords(filteredRecords);
			setPage(1);
		}
	}, [
		stationFilterValue,
		statusFilterValue,
		priorityFilterValue,
		problemFilterValue,
		dateFilterValue,
	]);

	useEffect(() => {
		if (issues?.results) {
			setStationForSelectData([...new Set(issues.results.map(stationName))]);

			const filteredRecords = filterRecords(
				issues.results,
				stationFilterValue,
				statusFilterValue,
				priorityFilterValue,
				problemFilterValue,
				dateFilterValue
			);
			setRecords(filteredRecords);
		}
	}, [issues]);

	if (isPending) {
		return <LoadingOverlay visible />;
	}

	if (isError) {
		return <Error />;
	}

	return (
		<Table<Issue>
			columns={columns}
			records={records}
			onRowClick={({ record }) => {
				navigate(`/issues/${record.id}`);
				showIssueModal();
			}}
			page={page}
			setPage={setPage}
		/>
	);
}

export default IssuesTable;
