import React, { useState } from "react";
import { Text, Image, Button, Group } from "@mantine/core";
import { format } from "date-fns";
import Section from "../Section";
import { PlannedClosure } from "../../../utilities/api/jelbi-dashboard-api";
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/arrow-right-line.svg";
import styles from "./Closures.module.scss";
import StationClosures from "../StationClosures/StationClosures";

type ClosuresProps = {
	closures: PlannedClosure[];
};

function Closures({ closures }: ClosuresProps) {
	const { PUBLIC_URL } = process.env;
	const [showStationClosures, setShowStationClosures] = useState(false);
	return (
		<>
			<Section title="Sperrungen">
				<Group gap={8} className={styles.closure}>
					<Image
						h={16}
						src={`${PUBLIC_URL}/icons-map/jelbi_clock_badge.svg`}
						alt="Geplante Sperrung Badge"
					/>
					<Text size="sm" className={styles.closure__time}>
						{format(new Date(closures[0].start), `dd.MM.yyyy `)}
						<Text fs="italic" component="span">
							{format(new Date(closures[0].start), `HH:mm - `)}
						</Text>
						{format(new Date(closures[0].end), `dd.MM.yyyy `)}
						<Text fs="italic" component="span">
							{format(new Date(closures[0].end), `HH:mm`)}
						</Text>
					</Text>
				</Group>

				<Group>
					<Text size="sm" lh="120%" mt={8} c="var(--mantine-color-gray-6)">
						{closures[0].comment || ""}
					</Text>
				</Group>
				<Group justify="flex-end">
					<Button
						className={styles.closure__link}
						variant="subtle"
						rightSection={
							<ArrowRightIcon className={styles.closure__link__icon} />
						}
						onClick={() => setShowStationClosures(true)}
						title="alle Sperrungen anzeigen"
					>
						alle anzeigen
					</Button>
				</Group>
			</Section>
			<StationClosures
				key={String(showStationClosures)}
				isModalVisible={showStationClosures}
				setModalVisible={setShowStationClosures}
				closures={closures || []}
			/>
		</>
	);
}
export default Closures;
