import { IssueStatus } from "../../../../utilities/api/jelbi-dashboard-api";
import statusToFrontend from "../../utils/status-translation";

const statusValues: IssueStatus[] = [
	IssueStatus.Blocked,
	IssueStatus.Closed,
	IssueStatus.Commissioned,
	IssueStatus.InProgress,
	IssueStatus.Open,
];

const statusOptions: { key: IssueStatus; label: string; value: IssueStatus }[] =
	statusValues.map((item: IssueStatus) => ({
		key: item,
		label: statusToFrontend[item],
		value: item,
	}));

export default statusOptions;
