// disable eslint for using props spreading as it's best practices in https://mantine.dev/form/use-form/
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from "react";
import { GetInputPropsReturnType } from "@mantine/form/lib/types";
import { Select } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useUncontrolled } from "@mantine/hooks";
import useStationForSelectData from "../../utilities/client/hooks/useStationForSelectData";

type DashboardDateTimePickerProps = {
	formInputProps: GetInputPropsReturnType;
	label?: string;
	placeholder?: string;
	withAsterisk?: boolean;
	disabled?: boolean;
};

function DashboardSelectStation({
	formInputProps,
	label = "Standort",
	placeholder = "Bitte Station auswählen",
	withAsterisk = false,
	disabled = false,
}: DashboardDateTimePickerProps): ReactElement {
	const stationsSelectData = useStationForSelectData();

	// Support use in uncontrolled forms, see the following for details:
	// https://mantine.dev/form/uncontrolled/#uncontrolled-mode-in-custom-components
	const [value, handleChange] = useUncontrolled({
		defaultValue: formInputProps.defaultValue,
		onChange: formInputProps.onChange,
	});

	return (
		<Select
			{...formInputProps}
			value={value}
			onChange={handleChange}
			label={label}
			withAsterisk={withAsterisk}
			placeholder={placeholder}
			data={stationsSelectData}
			searchable
			disabled={disabled}
			leftSection={<IconSearch size={16} />}
		/>
	);
}

export default DashboardSelectStation;
