import React, { ReactElement } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { Button, Card, Center, Group, Stack, Text, Title } from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";
import useGetUserRoles from "../../utilities/client/hooks/useGetUserRoles";
import { mapUserRoleToString } from "../../utilities/client/roles.util";
import classes from "./Profile.module.scss";
import useIsMobileView from "../../utilities/client/hooks/useIsMobileView";

export default function UserProfile(): ReactElement {
	const { instance } = useMsal();
	const account = useAccount();
	const userRoles = useGetUserRoles();
	const isMobileView = useIsMobileView();

	return (
		<Stack className={classes.container}>
			<Title order={3}>Profil</Title>
			<Center>
				<Stack style={{ width: 500 }} gap={24}>
					<Card withBorder>
						<Card.Section
							withBorder
							px="lg"
							py="lg"
							bg="var(--mantine-color-gray-light)"
						>
							<Text size="sm" fw={900}>
								Name
							</Text>
						</Card.Section>

						<Card.Section px="lg" py="lg">
							<Text size="sm">{account?.name}</Text>
						</Card.Section>
					</Card>

					<Card withBorder>
						<Card.Section
							withBorder
							px="lg"
							py="lg"
							bg="var(--mantine-color-gray-light)"
						>
							<Text size="sm" fw={900}>
								Rolle
							</Text>
						</Card.Section>
						<Card.Section px="lg" py="lg">
							<Text size="sm">
								{userRoles.map((role) => mapUserRoleToString(role)).join(", ")}
							</Text>
						</Card.Section>
					</Card>
					<Group justify="flex-end" grow={isMobileView}>
						<Button
							leftSection={<IconLogout />}
							onClick={() => instance.logoutRedirect()}
						>
							Abmelden
						</Button>
					</Group>
				</Stack>
			</Center>
			{process.env.REACT_APP_MONOREPO_VERSION && (
				<Text className={classes.version}>
					{process.env.REACT_APP_MONOREPO_VERSION}
					{process.env.REACT_APP_STAGE_NAME_SUFFIX}
				</Text>
			)}
		</Stack>
	);
}
