import { IssuePriority } from "../../../../utilities/api/jelbi-dashboard-api";
import priorityToFrontend from "../../utils/priority-translation";

const statusValues: IssuePriority[] = [
	IssuePriority.Low,
	IssuePriority.Medium,
	IssuePriority.High,
];

const statusOptions: {
	key: IssuePriority;
	label: string;
	value: IssuePriority;
}[] = statusValues.map((item: IssuePriority) => ({
	key: item,
	label: priorityToFrontend[item],
	value: item,
}));

export default statusOptions;
