import React from "react";
import { Flex, Stack, Text } from "@mantine/core";
import Section from "../Section";
import { ReactComponent as ChargingStationIcon } from "../../../assets/icons/bvg-e-charging-line.svg";
import { ReactComponent as SmartBenchIcon } from "../../../assets/icons/smart-bench-line.svg";
import { ReactComponent as WifiIcon } from "../../../assets/icons/wifi-line.svg";
import {
	Station,
	VehicleType,
} from "../../../utilities/api/jelbi-dashboard-api";
import VehicleImage from "../../VehicleImage/VehicleImage";

type EquipmentsProps = {
	station: Station;
	iconSize: number;
};

function Equipments({ station, iconSize }: EquipmentsProps) {
	const iconStyles = {
		height: `${iconSize}px`,
		width: `${iconSize}px`,
	};

	const cargoBikeCount =
		station.vehicles.filter(
			({ vehicleType }) => vehicleType === VehicleType.CargoBike
		)[0]?.capacity ?? 0;

	const hasEquipment =
		cargoBikeCount > 0 ||
		station.hasWifi ||
		station.hasSmartBench ||
		station.hasCarChargingStation;
	return (
		<Section title="Ausstattung">
			<Stack gap={8}>
				{cargoBikeCount > 0 && (
					<Flex gap={12} align="center">
						<VehicleImage size={26} vehicleType={VehicleType.CargoBike} />
						<Text size="sm">
							{cargoBikeCount} Lastenrad-Stell
							{cargoBikeCount === 1 ? "platz" : "plätze"}
						</Text>
					</Flex>
				)}
				{station.hasWifi && (
					<Flex gap={12} align="center">
						<WifiIcon name="Wifi" style={iconStyles} />
						<Text size="sm">Wifi</Text>
					</Flex>
				)}
				{station.hasSmartBench && (
					<Flex gap={12} align="center">
						<SmartBenchIcon name="Intelligente Sitzbank" style={iconStyles} />
						<Text size="sm">Smart Bench</Text>
					</Flex>
				)}
				{station.hasCarChargingStation && (
					<Flex gap={12} align="center">
						<ChargingStationIcon name="Auto Aufladesäule" style={iconStyles} />
						<Text size="sm">Carsharing Ladesäule(n)</Text>
					</Flex>
				)}
				{!hasEquipment && <Text size="sm">keine</Text>}
			</Stack>
		</Section>
	);
}
export default Equipments;
