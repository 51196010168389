import React, { ReactElement, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { subYears } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";
import { validate as isValidUUID } from "uuid";
import {
	Button,
	Group,
	Modal,
	SimpleGrid,
	Stack,
	Tabs,
	Title,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import issuesService from "../../services/issuesService";
import { QUERY_KEY_ALL_TRACKED_ISSUES } from "../../utilities/client/query-keys";
import IssuesTable from "./IssuesTable/IssuesTable";
import classes from "./Issues.module.scss";
import ReadIssue from "./ReadIssue/ReadIssue";

const TAB_VALUE_ALL = "all";
const TAB_VALUE_TRACKED = "tracked";

function Issues(): ReactElement {
	const [page, setPage] = useState<number>(1);
	const navigate = useNavigate();
	const { issueId: shownIssueId } = useParams();
	const [isIssueModalShown, { open: showIssueModal, close: closeIssueModal }] =
		useDisclosure(false);

	const { data: trackedIssuesCollection } = useQuery({
		queryKey: [QUERY_KEY_ALL_TRACKED_ISSUES],
		queryFn: () =>
			issuesService.fetchIssues({
				start: subYears(new Date(), 1).toISOString(),
				end: new Date().toISOString(),
				isTracked: true,
			}),
	});

	useEffect(() => {
		if (shownIssueId) {
			if (isValidUUID(shownIssueId)) {
				showIssueModal();
			} else {
				navigate("/issues");
			}
		}
	}, []);

	return (
		<>
			<Stack className={classes.container}>
				<SimpleGrid className={classes.header}>
					<Title order={3}>Problemmeldungen</Title>
					<Group justify="flex-end">
						<Link to="add">
							<Button leftSection={<IconPlus />}>Neue Problemmeldung</Button>
						</Link>
					</Group>
				</SimpleGrid>
				<Tabs defaultValue={TAB_VALUE_ALL} onChange={() => setPage(1)}>
					<Tabs.List>
						<Tabs.Tab value={TAB_VALUE_ALL}>Alle</Tabs.Tab>
						<Tabs.Tab value={TAB_VALUE_TRACKED}>
							Zur Nachverfolgung
							{trackedIssuesCollection?.results.length
								? ` (${trackedIssuesCollection.results.length})`
								: ""}
						</Tabs.Tab>
					</Tabs.List>

					<Tabs.Panel value={TAB_VALUE_ALL}>
						<IssuesTable
							page={page}
							setPage={setPage}
							showIssueModal={showIssueModal}
						/>
					</Tabs.Panel>
					<Tabs.Panel value={TAB_VALUE_TRACKED}>
						<IssuesTable
							page={page}
							setPage={setPage}
							showIssueModal={showIssueModal}
							showTrackedIssuesOnly
						/>
					</Tabs.Panel>
				</Tabs>
			</Stack>
			{shownIssueId && (
				<Modal
					fullScreen
					withCloseButton={false}
					opened={isIssueModalShown}
					onClose={() => {
						closeIssueModal();
						navigate(`/issues`);
					}}
					zIndex="99"
					classNames={{ body: classes.modal }}
				>
					<ReadIssue
						issueId={shownIssueId}
						onClose={() => {
							closeIssueModal();
							navigate(`/issues`);
						}}
					/>
				</Modal>
			)}
		</>
	);
}

export default Issues;
