/* eslint-disable react/jsx-props-no-spreading */
import { Text, TextProps } from "@mantine/core";
import React, { ReactElement } from "react";
import { IssueStatus } from "../../../utilities/api/jelbi-dashboard-api";
import statusToFrontend from "../utils/status-translation";

type StatusProps = {
	props?: TextProps;
	status: IssueStatus;
};

function Status({ props, status }: StatusProps): ReactElement {
	return (
		<Text ta="left" size="sm" {...props}>
			{statusToFrontend[status]}
		</Text>
	);
}

export default Status;
