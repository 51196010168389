import React, { ReactElement } from "react";
import { Stack, Table, Image, Text, Group } from "@mantine/core";
import {
	VehicleType,
	VehiclesStatistic,
} from "../../../../../../utilities/api/jelbi-dashboard-api";

import {
	getVehicleTypeImage,
	getVehicleTypeName,
} from "../../../../../../utilities/client/vehicles";
import MspImage from "../../../../../MspImage/MspImage";
import {
	getVehicleAmount,
	getVehicleAmounts,
} from "../../../../../../utilities/client/vehicle-amount";

const { PUBLIC_URL } = process.env;
const FONT_SIZE_AMOUNT = "md";

export const getHead = (
	availableVehicleTypes: VehicleType[]
): ReactElement[] => {
	return availableVehicleTypes.map((vehicleType) => (
		<Table.Th key={vehicleType}>
			<Stack align="center" gap={0}>
				<Image
					src={PUBLIC_URL + getVehicleTypeImage(vehicleType)}
					alt={getVehicleTypeName(vehicleType)}
					w="38px"
				/>
				<Text size="sm">{getVehicleTypeName(vehicleType)}</Text>
			</Stack>
		</Table.Th>
	));
};

export const getRowTds = (
	availableVehicleTypes: VehicleType[],
	availabilities: VehiclesStatistic,
	showMsps: boolean
): ReactElement[] => {
	return availableVehicleTypes.map((vehicleType) => {
		if (showMsps) {
			const amountDetailed = getVehicleAmounts(
				availabilities.vehicleDistributions,
				vehicleType
			);

			const vehiclesPerMsp = amountDetailed.map((vehicle) => (
				<Group justify="center" key={vehicle.mspId}>
					<Group justify="flex-start">
						<Text size={FONT_SIZE_AMOUNT}>{vehicle.amount}</Text>
						<MspImage mspId={vehicle.mspId || ""} />
					</Group>
				</Group>
			));

			return (
				<Table.Td align="center" key={vehicleType}>
					{vehiclesPerMsp.length ? (
						vehiclesPerMsp
					) : (
						<Text size={FONT_SIZE_AMOUNT}>0</Text>
					)}
				</Table.Td>
			);
		}

		const amount = getVehicleAmount(
			availabilities.vehicleDistributions,
			vehicleType
		);

		return (
			<Table.Td align="center" key={vehicleType}>
				<Text size={FONT_SIZE_AMOUNT}>{amount}</Text>
			</Table.Td>
		);
	});
};
