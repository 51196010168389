import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Burger, Group, MantineBreakpoint, Menu, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useLocation } from "react-router";
import classes from "./Navbar.module.scss";
import useGetUserRoles from "../../utilities/client/hooks/useGetUserRoles";
import {
	isAdminOrHigher,
	isRangerOrHigher,
} from "../../utilities/client/roles.util";

const BURGER_MENU_BREAKPOINT: MantineBreakpoint = "lg";

function Navbar(): ReactElement {
	const { PUBLIC_URL } = process.env;
	const [isBurgerMenuOpen, { toggle: toggleBurgerMenu }] = useDisclosure(false);
	const location = useLocation();
	const userRoles = useGetUserRoles();

	const links = [
		{
			href: "/",
			label: "Stationsübersicht",
		},
		{
			href: "/inspections",
			label: "Begehungen",
		},
		{
			href: "/issues",
			label: "Problemmeldungen",
		},
		{
			href: "/msps",
			label: "MSPs",
		},
		{
			href: "/profile",
			label: "Profil",
		},
	];

	const items = links
		.filter(
			({ href }) =>
				(isRangerOrHigher(userRoles) || href !== "/inspections") &&
				(isAdminOrHigher(userRoles) || href !== "/msps")
		)
		.map((link) => (
			<Link
				key={link.label}
				to={link.href}
				className={[
					classes.navbar__link,
					location.pathname === link.href && classes.navbar__link__active,
				].join(" ")}
			>
				<Text>{link.label}</Text>
			</Link>
		));

	return (
		<Group
			align="center"
			justify="space-between"
			className={classes.navbar__row}
		>
			<Group>
				<Link to="/" className={classes.navbar__logo}>
					<img
						src={`${PUBLIC_URL}/logo.svg`}
						alt="Navbar Logo"
						className={classes.navbar__logo__image}
					/>
				</Link>
			</Group>
			<Group gap={60} visibleFrom={BURGER_MENU_BREAKPOINT}>
				{items}
			</Group>
			<Menu opened={isBurgerMenuOpen} onChange={toggleBurgerMenu}>
				<Menu.Target>
					<Burger
						opened={isBurgerMenuOpen}
						onClick={toggleBurgerMenu}
						hiddenFrom={BURGER_MENU_BREAKPOINT}
						size="sm"
						aria-label={`Navigation {isBurgerMenuOpen ? "schließen" : "öffnen"}`}
					/>
				</Menu.Target>
				<Menu.Dropdown>
					{items.map((item) => (
						<Menu.Item
							key={item.key}
							className={classes.navbar__burgermenu__item}
						>
							{item}
						</Menu.Item>
					))}
				</Menu.Dropdown>
			</Menu>
		</Group>
	);
}

export default Navbar;
