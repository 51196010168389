import { isNotEmpty } from "@mantine/form";
import { FormRulesRecord } from "@mantine/form/lib/types";
import { AddIssueFormValues } from "./add-issue-types";
import { Photo } from "../../../PhotoUpload/PhotoUpload";

const validate: FormRulesRecord<AddIssueFormValues> = {
	createdAt: isNotEmpty("Bitte Datum angeben"),
	description: isNotEmpty("Bitte Beschreibung hinzufügen"),
	equipment: isNotEmpty("Bitte Ausstattung auswählen"),
	problem: isNotEmpty("Bitte Problem auswählen"),
	stationId: isNotEmpty("Bitte Station auswählen"),
	photos: (value, values) =>
		values.photos.length < 1 ||
		value.some(({ isProcessing }: Photo) => isProcessing === true),
};

export default validate;
