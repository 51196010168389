import React from "react";
import { GeoJSON, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { renderToString } from "react-dom/server";
import { Divider } from "@mantine/core";
import {
	Station,
	StationState,
} from "../../../utilities/api/jelbi-dashboard-api";
import JelbiColor from "../../../utilities/client/jelbi-colors";
import classes from "./StationMarker.module.scss";
import VehicleStatsTable from "../VehicleStatsTable/VehicleStatsTable";
import IssueMarkerBadge from "../IssueMarkerBadge";
import PlannedClosureBadge from "../PlannedClosureBadge";
import MicromobilityAvailabilityBadge from "../MicromobilityAvailabilityBadge";
import stationService from "../../../services/stationService";

type StationMarkerProps = {
	station: Station;
	setSelectedStation: (station: Station) => void;
	showStationGeofence: boolean;
	opacity: number;
};

function StationMarker({
	station,
	setSelectedStation,
	showStationGeofence,
	opacity,
}: StationMarkerProps): JSX.Element {
	const { PUBLIC_URL } = process.env;
	let iconImage;
	if (station.state === StationState.Active) {
		iconImage = station.isPoint
			? `${PUBLIC_URL}/icons-map/point-active.png`
			: `${PUBLIC_URL}/icons-map/station-active.png`;
	} else {
		iconImage = station.isPoint
			? `${PUBLIC_URL}/icons-map/point-inactive.png`
			: `${PUBLIC_URL}/icons-map/station-inactive.png`;
	}

	const [lng, lat] = station.geolocation.coordinates;

	const showVehicleAppearanceBadge = station.vehicles.some(
		({ expectation: { actual, expected } }) => actual < expected
	);

	const hasZeroMicromobilityAvailability =
		stationService.hasZeroMicromobilityAvailability(station.vehicles);
	const isOverMicromobilityToleranceLimit =
		stationService.isOverMicromobilityToleranceLimit(station.vehicles);

	const icon = new L.DivIcon({
		html: renderToString(
			<div>
				<img className={classes.icon} src={iconImage} alt={station.name} />
				<div className={classes["icon__top-right-icons"]}>
					<IssueMarkerBadge issueCount={station.issues.count} />
				</div>
				<div className={classes["icon__bottom-right-icons"]}>
					{(isOverMicromobilityToleranceLimit ||
						hasZeroMicromobilityAvailability) && (
						<MicromobilityAvailabilityBadge
							state={
								isOverMicromobilityToleranceLimit
									? "OVERFILLED"
									: "INSUFFICIENT"
							}
						/>
					)}
				</div>
				<div className={classes["icon__bottom-left-icons"]}>
					{station.plannedClosures.enabled && <PlannedClosureBadge />}
				</div>
			</div>
		),
		className: classes.badge__badgeBackground,
		popupAnchor: [0, -17],
		iconAnchor: [19, 19],
	});

	return (
		<div key={`${station.id}-icon`}>
			<Marker
				alt={
					`${station.id},${station.issues.count},${showVehicleAppearanceBadge},${station.plannedClosures.enabled},` +
					`${hasZeroMicromobilityAvailability},${isOverMicromobilityToleranceLimit}`
				} // this is used to show badges on a cluster
				key={station.id}
				position={[lat, lng]}
				icon={icon}
				opacity={opacity}
				eventHandlers={{
					mouseover: (e) => e.target.openPopup(),
					mousedown: (e) => e.target.closePopup(),
					click: () => setSelectedStation(station),
				}}
			>
				<Popup key={`${station.id}-popup`} className={classes.popup}>
					<span className={classes.title}>{station.name}</span>
					<Divider className={classes.divider} />
					<VehicleStatsTable vehicles={station.vehicles} />
				</Popup>
			</Marker>
			{showStationGeofence && (
				<GeoJSON
					style={{
						color:
							station.state === StationState.Active
								? JelbiColor.MAIN
								: JelbiColor.GRAY_INACTIVE,
					}}
					data={station.geofence}
				/>
			)}
		</div>
	);
}

export default StationMarker;
