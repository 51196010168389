/* eslint-disable react/jsx-props-no-spreading */
import { Pill, PillProps } from "@mantine/core";
import React, { ReactElement } from "react";
import { IssuePriority } from "../../../utilities/api/jelbi-dashboard-api";
import priorityToFrontend from "../utils/priority-translation";

type PriorityPillProps = {
	pillProps?: PillProps;
	priority: IssuePriority;
};

function PriorityPill({
	pillProps,
	priority,
}: PriorityPillProps): ReactElement {
	return (
		<Pill
			{...pillProps}
			bg={
				{
					[IssuePriority.Low]: "none",
					[IssuePriority.Medium]: "var(--mantine-color-yellow-2)",
					[IssuePriority.High]: "var(--mantine-color-red-2)",
				}[priority]
			}
			style={
				priority === IssuePriority.Low
					? { border: "1px solid var(--mantine-color-gray-4)" }
					: {}
			}
		>
			{priorityToFrontend[priority]}
		</Pill>
	);
}

export default PriorityPill;
