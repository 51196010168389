import React, { ReactElement } from "react";
import {
	Button,
	Group,
	Modal,
	ScrollArea,
	Text,
	Timeline,
} from "@mantine/core";
import { isWithinInterval, format } from "date-fns";
import { PlannedClosure } from "../../../utilities/api/jelbi-dashboard-api";
import classes from "./StationClosures.module.scss";

type StationClosuresProps = {
	isModalVisible: boolean;
	setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	closures: PlannedClosure[];
};

function StationClosures({
	isModalVisible,
	setModalVisible,
	closures,
}: StationClosuresProps): ReactElement {
	const timelineItems = closures.map(({ start, end, comment }) => {
		return (
			<Timeline.Item>
				{[start, end].map((date) => (
					<Text
						size="sm"
						className={classes["station-closures-modal__timeline__time"]}
					>
						{format(date, `dd.MM.yyyy - `)}
						<span>{format(date, `HH:mm `)}Uhr</span>
					</Text>
				))}

				{comment && (
					<Text
						size="sm"
						className={classes["station-closures-modal__timeline__comment"]}
					>
						{comment}
					</Text>
				)}
			</Timeline.Item>
		);
	});

	const activeItem = closures.findIndex(({ start, end }) =>
		isWithinInterval(new Date(), { start, end })
	);

	return (
		<Modal
			title="Sperrungen"
			classNames={{ content: classes["station-closures-modal"] }}
			opened={isModalVisible}
			onClose={() => setModalVisible(false)}
			centered
			closeButtonProps={{ size: "xl" }}
		>
			<ScrollArea.Autosize mah="50dvh">
				<Timeline active={activeItem} bulletSize={10} lineWidth={2}>
					{timelineItems}
				</Timeline>
			</ScrollArea.Autosize>
			<Group justify="right">
				<Button
					onClick={() => setModalVisible(false)}
					className={classes["station-closures-modal__close-button"]}
					mt={32}
				>
					Schließen
				</Button>
			</Group>
		</Modal>
	);
}

export default StationClosures;
