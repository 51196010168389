/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	Button,
	Grid,
	Group,
	InputLabel,
	Stack,
	Title,
	Text,
	LoadingOverlay,
	Box,
	Pill,
	Select,
} from "@mantine/core";
import { ok } from "@oazapfts/runtime";
import Lightbox, { SlideImage } from "yet-another-react-lightbox";
import { useDisclosure } from "@mantine/hooks";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";
import { useField } from "@mantine/form";
import IssueComment from "../IssueComments/IssueComments";
import {
	IssuePriority,
	IssueStatus,
	getIssue,
	getPhoto,
	patchIssue,
} from "../../../utilities/api/jelbi-dashboard-api";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import classes from "./ReadIssue.module.scss";
import { humanizedDateTimeFormat } from "../../../utilities/client/date-format";
import {
	getIssueQueryKey,
	getPhotosQueryKey,
} from "../../../utilities/client/query-keys";
import ImageWithOverlay from "../../ImageWithOverlay/ImageWithOverlay";
import Error from "../../Error/Error";
import getProblemTagStyle from "../../../styles/getProblemTagStyle";
import statusOptions from "../AddIssue/utils/status-options";
import priorityOptions from "../AddIssue/utils/priority-options";
import { updateCachedIssues } from "../IssuesTable/utils/helper";
import {
	showSubmitErrorNotification,
	showSubmitSuccessfulNotification,
} from "./utils/notifications";
import useGetUserRoles from "../../../utilities/client/hooks/useGetUserRoles";
import { isRangerOrHigher } from "../../../utilities/client/roles.util";
import PriorityPill from "../PriorityPill/PriorityPill";
import Status from "../Status/Status";
import DashboardTextarea from "../../DashboardTextarea/DashboardTextarea";

const IMAGE_SIZE = 100;

type ReadIssueProps = {
	issueId: string;
	onClose: () => void;
};

function ReadIssue({ issueId, onClose }: ReadIssueProps): ReactElement {
	const userRoles = useGetUserRoles();
	const isMobile = useIsMobileView();
	const [isImagePreviewerOpen, imagePreviewerHandler] = useDisclosure(false);
	const [selectedImageId, setSelectedImageId] = useState(0);

	const { data: issue, isPending } = useQuery({
		queryKey: getIssueQueryKey(issueId || ""),
		queryFn: () => ok(getIssue(issueId || "")),
		// The query will not execute until routeMatch.params.mspId
		enabled: !!issueId,
	});
	const status = useField({
		initialValue: issue?.status ?? IssueStatus.Open,
	});
	const priority = useField({
		initialValue: issue?.priority ?? IssuePriority.Low,
	});
	const description = useField({
		initialValue: issue?.description,
	});
	const queryClient = useQueryClient();
	const { isPending: sendChangesIsPending, mutate: sendChanges } = useMutation({
		mutationFn: () =>
			patchIssue(issueId, {
				status: status.getValue(),
				priority: priority.getValue(),
				description: description.getValue(),
			}),
		onSuccess: () => {
			showSubmitSuccessfulNotification();
			if (issue) {
				issue.status = status.getValue();
				issue.priority = priority.getValue();
				issue.description = description.getValue() || "";
				updateCachedIssues(queryClient, issue.station.id);
			}
		},
		onError: () => showSubmitErrorNotification(),
	});
	useEffect(() => {
		if (issue) {
			if (issue.status !== status.getValue()) {
				status.setValue(issue.status);
			}
			if (issue?.priority !== priority.getValue()) {
				priority.setValue(issue?.priority);
			}
			if (issue?.description !== description.getValue()) {
				description.setValue(issue?.description);
			}
		}
	}, [issue]);

	const isFormDirty =
		status.isDirty() || priority.isDirty() || description.isDirty();
	const photoIds = issue?.photos || [];
	const getPhotos = useQuery({
		queryKey: [getPhotosQueryKey(issueId || "")],
		queryFn: async () =>
			// eslint-disable-next-line no-return-await
			Promise.all(photoIds.map(async (photo) => await getPhoto(photo.id))),
		// The query will not execute until routeMatch.params.mspId
		enabled: !!issue,
	});

	let photos: JSX.Element[] = [];
	const slidePhotos: SlideImage[] = [];

	if (getPhotos.isSuccess) {
		const photosData = getPhotos.data.map((response) => response.data);
		photos = photosData.map((photoData, i) => {
			let photoUri = "";

			if (typeof photoData === "string") {
				photoUri = `data:image/jpeg;base64,${photoData}`;
				slidePhotos.push({
					src: photoUri,
					download: {
						url: "",
						filename: `${issueId}-${i}.jpeg`,
					},
				});
			}

			return (
				<ImageWithOverlay
					key={crypto.randomUUID()}
					src={photoUri}
					onClickHandler={() => {
						if (photoUri !== "") {
							imagePreviewerHandler.open();
							setSelectedImageId(i);
						}
					}}
					size={IMAGE_SIZE}
				/>
			);
		});
	} else {
		photos = photoIds.map(() => (
			<Box
				pos="relative"
				h={IMAGE_SIZE}
				w={IMAGE_SIZE}
				key={crypto.randomUUID()}
			>
				<LoadingOverlay visible />
			</Box>
		));
	}

	if (isPending) {
		return <LoadingOverlay visible />;
	}

	if (!issue) {
		return <Error />;
	}

	return (
		<>
			<Stack className={classes.container} mt={90}>
				<Title order={3}>Problemmeldung</Title>
				<Grid gutter={{ base: 28, lg: 60 }}>
					<Grid.Col span={{ base: 12, lg: 6 }}>
						<InputLabel>Standort</InputLabel>
						<Text>{issue.station.shortName}</Text>
						<Text>{issue.station.name}</Text>
					</Grid.Col>
					<Grid.Col span={{ base: 12, lg: 6 }}>
						<InputLabel>Datum</InputLabel>
						<Text>{humanizedDateTimeFormat(new Date(issue.createdAt))}</Text>
					</Grid.Col>
					<Grid.Col span={{ base: 12, lg: 6 }}>
						<InputLabel>Ausstattung</InputLabel>
						<Text>{issue.equipment}</Text>
					</Grid.Col>
					<Grid.Col span={{ base: 12, lg: 6 }}>
						<InputLabel>Problem</InputLabel>
						<Text>
							<Pill bg={getProblemTagStyle(issue.problem)} mb={4} size="md">
								{issue.problem}
							</Pill>
						</Text>
					</Grid.Col>
					<Grid.Col span={{ base: 12, lg: 6 }}>
						<InputLabel>Status</InputLabel>
						{isRangerOrHigher(userRoles) ? (
							<Select
								{...status.getInputProps()}
								key={status.key}
								data={statusOptions}
								contentEditable={issue === undefined}
								clearable={status.getValue() !== issue.status}
								onChange={(value) =>
									value && status.setValue(value as IssueStatus)
								}
								onClear={() => status.setValue(issue.status)}
							/>
						) : (
							<Text>
								<Status status={issue.status} props={{ size: "md" }} />
							</Text>
						)}
					</Grid.Col>
					<Grid.Col span={{ base: 12, lg: 6 }}>
						<InputLabel>Priorität</InputLabel>
						{isRangerOrHigher(userRoles) ? (
							<Select
								{...priority.getInputProps()}
								key={priority.key}
								data={priorityOptions}
								contentEditable={issue === undefined}
								clearable={priority.getValue() !== issue.priority}
								onChange={(value) =>
									value && priority.setValue(value as IssuePriority)
								}
								onClear={() => priority.setValue(issue.priority)}
							/>
						) : (
							<Text>
								<PriorityPill
									priority={issue.priority}
									pillProps={{ mb: 4, size: "md" }}
								/>
							</Text>
						)}
					</Grid.Col>
					<Grid.Col span={{ base: 12, lg: 12 }}>
						{isRangerOrHigher(userRoles) ? (
							<DashboardTextarea
								key={description.key}
								formInputProps={description.getInputProps()}
								label="Beschreibung"
								withAsterisk
								placeholder="Beschreibe das Problem"
							/>
						) : (
							<>
								<InputLabel>Beschreibung</InputLabel>
								<Text>{issue.description}</Text>
							</>
						)}
					</Grid.Col>
					{photos.length ? (
						<Grid.Col span={12}>
							<InputLabel>Fotos</InputLabel>
							<Group>{photos}</Group>
						</Grid.Col>
					) : (
						""
					)}
					<Grid.Col span={12}>
						<IssueComment issueId={issue.id} />
					</Grid.Col>
				</Grid>
			</Stack>
			<Box className={classes.footer} component="footer">
				<Group
					className={classes.buttons}
					justify="flex-end"
					style={{
						flexDirection: isMobile ? "column-reverse" : "row",
					}}
				>
					<Button
						variant="primary"
						fullWidth={isMobile}
						onClick={onClose}
						disabled={sendChangesIsPending}
						display={isFormDirty ? "none" : "block"}
					>
						Schließen
					</Button>
					<Button
						variant="outline"
						color="var(--mantine-color-red-8)"
						fullWidth={isMobile}
						onClick={() => {
							status.reset();
							priority.reset();
							description.reset();
						}}
						disabled={sendChangesIsPending}
						display={isFormDirty ? "block" : "none"}
					>
						Änderungen verwerfen
					</Button>
					<Button
						variant="primary"
						fullWidth={isMobile}
						onClick={() => sendChanges()}
						disabled={sendChangesIsPending || description.getValue() === ""}
						display={isFormDirty ? "block" : "none"}
					>
						Änderungen speichern
					</Button>
				</Group>
			</Box>
			<Lightbox
				open={isImagePreviewerOpen}
				close={imagePreviewerHandler.close}
				slides={slidePhotos}
				plugins={[Zoom, Download]}
				index={selectedImageId}
				zoom={{
					scrollToZoom: true,
					maxZoomPixelRatio: 3,
				}}
				controller={{ closeOnBackdropClick: true }}
			/>
		</>
	);
}

export default ReadIssue;
