import React, { ReactElement } from "react";
import { Table, TableData, Text } from "@mantine/core";
import classes from "./VehicleStatsTable.module.scss";
import VehicleImage from "../../VehicleImage/VehicleImage";
import {
	VehicleType,
	Vehicles,
} from "../../../utilities/api/jelbi-dashboard-api";
import { isMicromobility } from "../../../utilities/client/vehicles";

type VehicleStatsTableProps = {
	vehicles: Vehicles;
};

const orderVehicleTypes = (vehicleA: Vehicles[0], vehicleB: Vehicles[1]) => {
	const orderOfVehicleType = (vehicleType: VehicleType) => {
		switch (vehicleType) {
			case VehicleType.Car:
				return 0;
			case VehicleType.StationBasedCar:
				return 1;
			case VehicleType.MotorScooter:
				return 2;
			case VehicleType.Bicycle:
				return 3;
			case VehicleType.KickScooter:
				return 4;
			case VehicleType.CargoBike:
			default:
				return 5;
		}
	};
	return (
		orderOfVehicleType(vehicleA.vehicleType) -
		orderOfVehicleType(vehicleB.vehicleType)
	);
};

function VehicleStatsTable({
	vehicles,
}: VehicleStatsTableProps): ReactElement {
	const availableVehicles = vehicles
		.sort(orderVehicleTypes)
		.filter(
			({ capacity, vehicleType }) =>
				isMicromobility(vehicleType) || capacity > 0
		);
	const vehicleColumnHeaders = availableVehicles.map((vehicle) => (
		<VehicleImage vehicleType={vehicle.vehicleType} size={28} />
	));

	const capacities = availableVehicles.map((vehicle) => (
		<Text size="sm" span>
			{vehicle.capacity}
		</Text>
	));
	const liveValues = availableVehicles.map((vehicle) => (
		<Text
			size="sm"
			span
			c={
				vehicle.availability < vehicle.expectation.expected
					? "red.8"
					: "green.9"
			}
		>
			{vehicle.availability}
		</Text>
	));
	const thresholds = availableVehicles.map((vehicle) => (
		<Text size="sm" span>
			{vehicle.expectation.expected}
		</Text>
	));

	const tableData: TableData = {
		head: ["", ...vehicleColumnHeaders],
		body: [
			[
				<Text size="sm" span>
					Live-Anzahl
				</Text>,
				...liveValues,
			],
			[
				<Text size="sm" span>
					Kapazität
				</Text>,
				...capacities,
			],
			[
				<Text size="sm" span>
					Grenzwert
				</Text>,
				...thresholds,
			],
		],
	};

	return (
		<Table
			highlightOnHover
			data={tableData}
			classNames={{ table: classes["vehicle-stats-table"] }}
		/>
	);
}

export default VehicleStatsTable;
