import { IssueStatus } from "../../../utilities/api/jelbi-dashboard-api";

const statusToFrontend: Record<IssueStatus, string> = {
	[IssueStatus.Blocked]: "Blockiert",
	[IssueStatus.Closed]: "Erledigt",
	[IssueStatus.Commissioned]: "DL beauftragt",
	[IssueStatus.InProgress]: "In Arbeit",
	[IssueStatus.Open]: "Offen",
};

export default statusToFrontend;
