import React, { ReactElement } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { AppShell } from "@mantine/core";
import Navbar from "../Navbar/Navbar";
import classes from "./ProtectedRoute.module.scss";

function ProtectedRoute({
	children,
}: {
	children: ReactElement;
}): ReactElement {
	const isAuthenticated = useIsAuthenticated();

	if (!isAuthenticated) {
		return <>Wird geladen...</>;
	}

	return (
		<AppShell header={{ height: { base: 72, lg: 80 } }}>
			<AppShell.Header className={classes.header}>
				<Navbar />
			</AppShell.Header>

			<AppShell.Main>
				<div>{children}</div>
			</AppShell.Main>
		</AppShell>
	);
}

export default ProtectedRoute;
